import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { LeavingModal } from "../LeavingModal";

import { createClickTracker } from "../../analytics";

import "./link.scss";

/**
 * A Link, with lots of extra options.
 */
export function Link(props) {
  const {
    analytics,
    className,
    downArrow,
    href,
    mailTo,
    onClick,
    to,
    ...linkProps
  } = props;

  if (className != null || downArrow) {
    linkProps.className = [className, downArrow && "link--down-arrow"]
      .filter(x => x)
      .join(" ");
  }

  if (to) {
    linkProps.to = to;
  } else if (href) {
    linkProps.to = href;
  } else if (mailTo) {
    linkProps.to = `mailto:${mailTo}`;
  }

  if (!linkProps.to) {
    throw new Error("Link missing `to` prop");
  }

  linkProps.onClick = createClickTracker({ analytics, onClick });

  // For mailto: links or links to downloadable assets, we just want to
  // use a normal <a> tag.
  const isMailTo = /^mailto:/.test(linkProps.to);
  const isFile = /^\/.*?[^/]+\.[^/]{3,4}$/.test(linkProps.to);

  if (isMailTo || isFile) {
    // eslint-disable-next-line
    return <a {...linkProps} href={linkProps.to} to={undefined} />;
  } else if (linkProps.to[0] === "#") {
    return <OnPageLink {...linkProps} />;
  } else if (linkProps.to[0] === "/") {
    return <OnSiteLink {...linkProps} />;
  } else {
    return <OffsiteLink {...linkProps} />;
  }

  function OnSiteLink(props) {
    return <RouterLink {...props} />;
  }

  function OffsiteLink(props) {
    const { children, onClick, noOffsiteWarning, to, ...rest } = props;
    const [modalVisible, setModalVisible] = useState(false);

    return (
      <>
        <a onClick={handleClick} href={to} {...rest}>
          {children}
        </a>
        {modalVisible && (
          <LeavingModal
            link={to}
            rel={rest.rel}
            target={rest.target || "_blank"}
            onClose={() => setModalVisible(false)}
          />
        )}
      </>
    );

    function handleClick(evt) {
      // Let e.g. analytics tracking fire before showing the offsite warning
      onClick && onClick.call(this, evt);

      if (!noOffsiteWarning) {
        evt.preventDefault();
        setModalVisible(true);
        return;
      }
    }
  }

  function OnPageLink(props) {
    const { children, onClick, to, ...rest } = props;

    return (
      <a href={to} onClick={handleOnPageNavLinkClick} {...rest}>
        {children}
      </a>
    );

    function handleOnPageNavLinkClick(evt) {
      const el = document.getElementById(props.to.substring(1));
      if (el) {
        evt.preventDefault();
        el.scrollIntoView({
          behavior: "smooth"
        });
        window.history.pushState(null, null, props.to);
      }
      onClick && onClick.call(this, evt);
    }
  }
}
