import React from "react";

import { Hr } from "../Hr";
import { Link } from "../Link";

import "./card.scss";

/**
 * Component for building a "card" tile.
 * @param {object} props
 * @param {any} props.children - Card content.
 * @param {string} props.className - class for card-specific styles.
 */
export function Card(props) {
  const {
    children,
    className,
    flat,
    giant,
    icon,
    link,
    linkTitle,
    small,
    title,
    ...wrapperProps
  } = props;

  const classes = [
    "card",
    className,
    giant && "card--giant",
    flat && "card--flat",
    icon && "card--has-icon",
    small && "card--small"
  ];

  let titleEl = null;
  if (title) {
    classes.push("card--has-title");

    if (title != null) {
      if (giant) {
        titleEl = <h2 className="card__title">{title}</h2>;
      } else {
        titleEl = (
          <>
            <h3 className="card__title">{title}</h3>
            <Hr skinny />
          </>
        );
      }
    }
  }

  let iconEl = null;
  if (typeof icon === "string") {
    iconEl = <img src={icon} alt="" className="card__icon" />;
  }

  let Wrapper = "div";
  let arrow = null;

  if (link) {
    Wrapper = Link;
    wrapperProps.to = link;

    if (linkTitle != null) {
      wrapperProps.title = linkTitle;
    }

    classes.push("card--link");
    arrow = (
      <span
        className="card__arrow"
        title="Arrow to indicate link to report an adverse event or product complaint"
      />
    );
  }

  return (
    <Wrapper {...wrapperProps} className={classes.filter(x => x).join(" ")}>
      {iconEl}
      {titleEl}
      {children}
      {arrow}
    </Wrapper>
  );
}
