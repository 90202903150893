/**
 * Action assigned to analytics events generated from clicks (by default).
 */
export const DEFAULT_CLICK_ACTION = "Clicked";

export function ga(...args) {
  if (typeof window.ga === "function") {
    window.ga(...args);
  }
}

export function trackPageView() {
  ga("send", "pageview");
}

export function trackEvent(event) {
  ga("send", {
    hitType: "event",
    eventCategory: event.eventCategory || event.category,
    eventAction: event.eventAction || event.action,
    eventLabel: event.eventLabel || event.label,
    eventValue: event.eventValue || event.value,
    nonInteraction: !!event.nonInteraction
  });
}

/**
 * Returns a function that can be assigned to an `onClick` prop to track
 * an event for a click.
 * @param {*} param0
 */
export function createClickTracker({ analytics, onClick }) {
  if (!analytics) {
    // No analytics event defined = no click tracking.
    return onClick;
  }

  return evt => {
    let label = analytics.label;

    if (label == null) {
      // Our default label will be the text of the element that just got clicked
      try {
        label = evt.target.innerText.replace(/(^\s*|\s*$)/g, "");
      } catch {}
    }

    trackEvent({
      action: DEFAULT_CLICK_ACTION,
      ...analytics,
      label
    });

    onClick && onClick(evt);
  };
}
