import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Link } from "../Link";

import logoUrl from "./images/spark-logo.png";
import logoUrl2x from "./images/spark-logo@2x.png";

import "./sitefooter.scss";

export function SiteFooter({ catNumber, copyrightYear, kiosk }) {
  return (
    <footer className="site-footer">
      <div className="adverse-event-banner">
        {!kiosk && (
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <p>
                  To report an adverse event or product complaint,{" "}
                  <Link
                    to="https://sparktx.com/aepc"
                    title="Learn how to report an adverse event or product complaint"
                    analytics={{
                      category: "External Links",
                      label: "Report AE / Product Effect (Footer)"
                    }}
                  >
                    click here
                  </Link>
                  .
                </p>
              </Col>
            </Row>
          </Grid>
        )}
      </div>
      <Grid fluid>
        <Row className="footer-content">
          <Col xs={12} md={8}>
            <div className="info">
              <p>
                <b>Spark Therapeutics, Inc.</b>
                <br />
                3737 Market Street
                <br />
                Philadelphia, PA 19104
                <br />
                Phone: 1-855-SPARKTX / +1 215-220-9300
              </p>
              <p>
                Spark, Spark Therapeutics and its design, and SparkMedical.com
                are trademarks or registered trademarks of Spark Therapeutics,
                Inc. in the United States.
                <br />
                &copy; {copyrightYear} Spark Therapeutics, Inc. All rights
                reserved.
                <br />
                {catNumber}
              </p>
            </div>
          </Col>
          {}
          <Col xs={12} md={4}>
            {!kiosk && (
              <ul className="footer-links">
                <li>
                  <Link
                    to="https://sparktx.com/privacy-policy/"
                    title="View Spark Therapeutics' privacy policy"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://sparktx.com/terms-of-use/"
                    title="View Spark Therapeutics' terms of use"
                  >
                    Terms of Use
                  </Link>
                </li>
              </ul>
            )}
            <div className="footer-logo">
              {!kiosk ? (
                <Link
                  to="http://sparktx.com"
                  title="View Spark Therapeutics website"
                >
                  <img
                    alt="Spark Therapeutics logo"
                    src={logoUrl}
                    srcSet={`${logoUrl} 1x, ${logoUrl2x} 2x`}
                  />
                </Link>
              ) : (
                <img
                  alt="Spark Therapeutics logo"
                  src={logoUrl}
                  srcSet={`${logoUrl} 1x, ${logoUrl2x} 2x`}
                />
              )}
            </div>
          </Col>
        </Row>
      </Grid>
    </footer>
  );
}
