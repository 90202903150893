import React from "react";

import { Link } from "../Link";

import { createClickTracker } from "../../analytics";

import "./button.scss";

import { ReactComponent as SearchIcon } from "./images/icon-search.svg";
import { ReactComponent as LoadingIcon } from "./images/icon-loading.svg";

/**
 * Component for building a "button".
 * @param {object} props
 * @param {any} props.children - Button content.
 * @param {string} props.href - Button url.
 * @param {string} props.className - class for button-specific styles.
 * @param {boolean} props.primary
 */
export function Button(props) {
  const {
    analytics,
    children,
    className,
    large,
    loading,
    link,
    onClick,
    primary,
    small,
    tiny,
    search,
    reset,
    ...buttonProps
  } = props;

  let Icon = null;

  if (search) {
    Icon = SearchIcon;
  } else if (loading) {
    Icon = LoadingIcon;
  }

  const classes = [
    className,
    "button",
    primary && "button--primary",
    !primary && "button--hollow",
    tiny && "button--tiny",
    small && "button--small",
    large && "button--large",
    reset && "button--reset",
    Icon && "button--has-icon"
  ]
    .filter(x => x)
    .join(" ");

  let Element = "button";

  if (link == null) {
    buttonProps.onClick = createClickTracker({ analytics, onClick });
  } else {
    // This "button" is actually a <Link> with button styling.
    // Use <Link> to render it, which will do its own analytics
    // tracking.
    Element = Link;
    Object.assign(buttonProps, {
      analytics,
      to: link,
      onClick
    });
  }

  return (
    <Element {...buttonProps} className={classes}>
      {Icon && (
        <span className="button__icon">
          <Icon />
        </span>
      )}
      {children}
    </Element>
  );
}
