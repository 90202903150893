import React from "react";

import "./hr.scss";

export function Hr({ color, hairline, skinny }) {
  const classes = [
    "hr",
    color && `hr--color-${color}`,
    hairline && "hr--hairline",
    skinny && "hr--skinny"
  ]
    .filter(x => x)
    .join(" ");
  return <hr className={classes} />;
}
